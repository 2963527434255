import "./index.css"
import Icon from '../../../../custom/icon';
import { useCallback, useEffect, useRef, useState } from "react";

const Text = props => {
    const { type, min, max, onEditComplete, onFocusChange, post, def } = props

    var [ value, setValue ] = useState(def)
    var [ focus, setFocus ] = useState()

    const inputRef = useRef()
    const isPara = type === 'para'

    // post-process value after blurred and forward focus event to parent component
    useEffect(() => {
        if(focus!==undefined){
            onFocusChange && onFocusChange(focus)
            if(!focus) {
                let v = (post && post(value)) || value
                setValue(v)
                onEditComplete && onEditComplete(v)
            }
        }
    }, [focus])

    const handleValueChange = useCallback(e => setValue(e.target.value))

    const handleFocus = () => setFocus(true)
    const handleBlur = () => setFocus(false)

    const clearInput = useCallback(() => {
        setValue("")
        setFocus(true)
        inputRef.current.focus()
    })

    return (
        <div className={`texin-root`}>
            {isPara?
                <textarea
                    ref={inputRef}
                    type={type}
                    className={`texin-field`}
                    onChange={handleValueChange}
                    value={value}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                />:                
                <input
                    ref={inputRef}
                    type={type}
                    min={min}
                    max={max}
                    className={`texin-field`}
                    onChange={handleValueChange}
                    value={value}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                />
            }
            {value && <Icon className="texin-close texin-icon" name="close" color="#888" size={4.5} onClick={clearInput}/>}
        </div>
    )
}

export default Text