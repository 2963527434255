import "./index.css"
import DP from '../../../components/custom/dp';
import Icon from '../../../components/custom/icon';
import Swipe from '../../../components/holders/swipe';
import moment from 'moment';
import Accordian from '../../../components/holders/accordian'
import MemberStats from '../../components/member-stats'
import { useCallback, useState } from "react";
import _ from "../../../_";
import { getDescription } from "../../../data/member";
import ContactBubble from "../../../components/holders/bubble/contact";

const Member = ({ member, onSwipe, hide }) => {

  const handleLeftSwipe = () => {
    setLeftSwipe(true)
    onSwipe && onSwipe(member)
  }

  var [showStats, setShowStats] = useState()
  var [leftSwipe, setLeftSwipe] = useState()

  const toggleStats = useCallback(() => {
    setShowStats(!showStats)
  }, [showStats])

  const isBuddyCoord = member.roleID == 'buddy-coord'

  return (
    <Swipe right={{ color: _.statusColors[member.status], padWidth: 1.5, minDrag: 10, maxDrag: 30, onSwipe: handleLeftSwipe }} hide={hide} className={"member-root"}>

      {leftSwipe ?
        <ContactBubble black phone={member.phone} name={member.name.split(" ")[0]} close={() => { setLeftSwipe(false) }} /> : null}

      <div className='member-box' key={member.name}>

        <div className='member-main-content'>

          <div className='member-dp-holder'>
            <DP user={member} size={15} className={"member-dp"} badge={
              member.roleID == "buddy-coord" ? {
                color: 'var(--bar)',
                icon: {
                  name: "group",
                  color: "#fff"
                }
              } : null
            } enlarge />
          </div>

          <div className='member-details-cont' onClick={toggleStats}>

            <div className='member-details'>
              <div className='member-name'>{member.name}</div>
              <div className='member-desc'>{getDescription(member)}</div>
            </div>

            <div className="member-right-holder">

            </div>

          </div>
        </div>

        <div className="member-sub-content-root">

          <div className="member-sub-content">
            {member.preacher && <div className='member-sub-item'>
              <Icon name="supervisor-account" size={[5, 1, 1]} color="#aaa" />
              <div className="member-sub-name left">{member.preacher}</div>
            </div>}

            <div className='member-sub-item'>
              {!isBuddyCoord && <Icon name="group" size={4.5} color="#aaa" />}
              <div className="member-sub-name left">{member.buddyName || (isBuddyCoord ? "Buddy Coordinator" : "-")}</div>
            </div>

            {member.courseID && <div className='member-sub-item'>
              <Icon name="stairs2" size={[5, 1, 1]} color="#aaa" />
              <div className="member-sub-name left">{member.courseID}</div>
            </div>}
          </div>

          <div className="member-sub-content">
            {
              member.dob && member.dob.dobDays() <= 7 ?
                <div className='member-sub-item'>
                  <Icon name="cake" color="#aaa" size={5.1} />
                  <div className="member-sub-name right">{moment(member.dob).format("DD MMM")}</div>
                </div>
                : null
            }
            {
              member.snooze && member.snooze.snoozeDays() ?
                <div className='member-sub-item'>
                  <Icon name="snooze" color="#aaa" size={4.9} />
                  <div className="member-sub-name right">{moment(member.snooze).format("DD MMM")}</div>
                </div>
                : null
            }
          </div>
        </div>

        <Accordian show={showStats} maxHeight="100vw">
          <MemberStats member={member} />
        </Accordian>
      </div>
    </Swipe>

  )

}


export default Member
