import "./index.css"
import DP from "../../../../components/custom/dp"
import Swipe from "../../../../components/holders/swipe"
import ContactBubble from "../../../../components/holders/bubble/contact"
import Icon from "../../../../components/custom/icon"
import { useGlobal } from "../../../../context/global"
import { useEffect, useState } from "react"
import PageLoader from "../../../../components/info/pageloader"
import Accordian from "../../../../components/holders/accordian"
import Input from "../../../../components/custom/input"
import moment from "moment"

const FOLKMemberHome = ()=>{

    const { auth, api } = useGlobal()
    const { user } = auth

    var [ member, setMember ] = useState()
    var [ courseAttendance, setCourseAttendnace ] = useState([])
    var [ sessionAttendance, setSessionAttendance ] = useState([])
    var [ fullAttendance, setFullAttendance ] = useState([])
    var [ progress, setProgress ] = useState([])
    var [ swipedConnect, setSwipedConnect ] = useState()
    var [ courseDrop, setCourseDrop ] = useState(false)
    var [ fullDrop, setFullDrop ] = useState(false)
    var [ progressDrop, setProgressDrop ] = useState(false)
    var [ showSessionAttendance, setShowSessionAttendance ] = useState(false)

    useEffect(()=>{
        api.call('/get-home-data', user).then(res=>{
            setMember(res.data[0][0])
            setCourseAttendnace(res.data[1])
            setSessionAttendance(res.data[2])
            setFullAttendance(res.data[3])
            setProgress(res.data[4])
        })
    }, [])


    const clearSwipedConnect = ()=>{
      setSwipedConnect(null)
    }
  
    const handlePreacherConnect = ()=>{
      setSwipedConnect([member.preacherPhone, 'FOLK Guide'])
    }

    const handleBuddyConnect = ()=>{
        setSwipedConnect([member.buddyPhone, 'Buddy'])
    }

    const toggleCourseDrop = ()=>{
        setCourseDrop(drop => !drop)
    }

    const toggleFullDrop = ()=>{
        setFullDrop(drop => !drop)
    }

    const toggleProgressDrop = ()=>{
        setProgressDrop(drop => !drop)
    }


    return (
        member?<>
            {swipedConnect?
                <ContactBubble phone={swipedConnect[0]} name={swipedConnect[1]} close={clearSwipedConnect}/> 
            :null}

            <div className='memhome-main'>
                <div>
                    <div className='memhome-head'>Course Info</div>

                    <div className="memhome-course-main">
                        {member.courseID && <div style={{width: '100%'}}>
                            <div className="memhome-course-body" onClick={toggleCourseDrop}>
                                <div className="memhome-course-block">
                                    <DP url={`https://cdn.iskconmysore.org/content?path=folkapp/logos/${member.courseID}.png`} enlarge size={15}/>
                                    <div className="memhome-course-body-cont">
                                        <div className="memhome-course-title">{member.courseName}</div>
                                        <div className="memhome-course-att">{`${member.sessionAttendance || 0} out of ${member.sessionCount} sessions attended`}</div>
                                    </div>
                                </div>
                                <Icon name='arrow-drop-down' className={`memhome-drop ${courseDrop?'up':''}`} size={8} color="#888"/>
                            </div>

                            <Accordian show={courseDrop} maxHeight={'50vh'}>
                                <div>

                                    <hr style={{width:'98%', opacity:0.5}}/>

                                    <div className="memhome-sesview">
                                        <Input type='checkbox' label='Timeline view' onEditComplete={setShowSessionAttendance}/>
                                    </div>

                                    {
                                        !showSessionAttendance?
                                            <div className="memhome-course-det-main">
                                                {
                                                    sessionAttendance.map((s, i) => 
                                                        <div className={`memhome-det-row ${i%2==1?'dark':''}`}>
                                                            <div className="memhome-det-font memhome-ssname">{s.name}</div>
                                                            <div style={{width:'10vw'}}/>
                                                            {s.date?<div className="memhome-det-font memhome-scourse-date">{moment(s.date, "YYYY-MM-DD").format("Do MMM 'YY")}</div>:<div className="memhome-det-font memhome-pending">PENDING</div>}
                                                        </div>
                                                    )
                                                }
                                            </div>:
                                            <div className="memhome-course-det-main">
                                                {
                                                    courseAttendance.map((s, i) => 
                                                        <div className={`memhome-det-row ${i%2==1?'dark':''}`}>
                                                            <div className="memhome-det-font memhome-course-date">{moment(s.date, "YYYY-MM-DD").format("Do MMM 'YY")}</div>
                                                            <div className="memhome-det-font memhome-sname">{s.name}</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                    }
                                </div>
                            </Accordian>

                        </div>}

                        <div className="memhome-progress-cont" onClick={toggleProgressDrop}>
                            <div className="memhome-progress-head">{`Progress`}</div>
                            <Icon name='arrow-drop-down' className={`memhome-drop ${progressDrop?'up':''}`} size={8} color="#888"/>
                        </div>

                        <Accordian show={progressDrop} maxHeight={'50vh'}>
                            <div className="memhome-course-det-main">
                                {
                                    progress.map((p, i) => 
                                        <div className={`memhome-det-row ${i%2==1?'dark':''}`}>
                                            <div className="memhome-det-font memhome-progress-title">{p.title}</div>
                                            <div className="memhome-det-font memhome-progress-status" >{p.date?`Received on
${moment(p.date, 'YYYY-MM-DD').format("Do MMM 'YY")}`:'Pending'}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordian>

                        <div className="memhome-att-cont" onClick={toggleFullDrop}>
                            <div className="memhome-att-head">{`Total Attendance: ${member.attendance || 0}`}</div>
                            <Icon name='arrow-drop-down' className={`memhome-drop ${fullDrop?'up':''}`} size={8} color="#888"/>
                        </div>

                        <Accordian show={fullDrop} maxHeight={'50vh'}>
                            <div className="memhome-course-det-main">
                                {
                                    fullAttendance.map((f, i) => 
                                        <div className={`memhome-det-row ${i%2==1?'dark':''}`}>
                                            <div className="memhome-det-font memhome-full-date">{moment(f.date, "YYYY-MM-DD").format("Do MMM 'YY")}</div>
                                            <div className="memhome-det-font memhome-cid">{f.courseID || "-"}</div>
                                            <div className="memhome-det-font memhome-sname" >{f.name}</div>
                                        </div>
                                    )
                                }
                            </div>
                        </Accordian>

                    </div>

                </div>
            </div>

            <div className='memhome-main'>

                <div className='memhome-head'>Get Connected..</div>

                {member.preacherID && <Swipe disabled width={"auto"} right={{minDrag:5, maxDrag: 20, onSwipe: handlePreacherConnect}}>
                    <div className='memhome-content'>
                        <div className="memhome-details-cont">
                            <DP size={[14, 6, 6]} user={{id: member.preacherID, name: member.preacherName}} enlarge badge={{color: "var(--bar)", icon: {name: "groups2", color: "white"}}}/>
                            <div className='memhome-details'>
                                <div className='memhome-title'>{"Your FOLK Guide"}</div>
                                <div className='memhome-name'>{member.preacherName}</div>
                            </div>
                        </div>
                        <Icon name="more-horiz" color="#888" onClick={handlePreacherConnect}/>
                    </div>
                </Swipe>}

                {member.buddyID && member.preacherID && <div className="memhome-sep"/>}

                {member.buddyID && <Swipe disabled width={"auto"} right={{minDrag:5, maxDrag: 20, onSwipe: handleBuddyConnect}}>
                    <div className='memhome-content'>
                        <div className="memhome-details-cont">
                            <DP size={[14, 6, 6]}  user={{id: member.buddyID, name: member.buddyName}} enlarge badge={{color: "var(--bar)", icon: {name: "group", color: "white"}}}/>
                            <div className='memhome-details'>
                                <div className='memhome-title'>{"Your FOLK Buddy"}</div>
                                <div className='memhome-name'>{member.buddyName}</div>
                            </div>
                        </div>
                        <Icon name="more-horiz" color="#888" onClick={handleBuddyConnect}/>
                    </div>
                </Swipe>}

                {false && <div className='memhome-note'>
                    <div>Swipe to contact</div>
                    <Icon name="keyboard-double-arrow-right" color="#aaa"/>
                </div>}

            </div>
        </>:<PageLoader text={"Loading home feed..."}/>
    )
}

export default FOLKMemberHome