import "./index.css"
import bg from "./bg"
import Field from "../../../components/custom/form/field"
import { useState } from "react"

const BGT = () => {

    var [ chapter, setChapter ] = useState(0)

    const config = {
        title: "Chapter",
        name: 'chapter',
        type: 'select',
        options: [
            "Chapter 1",
            "Chapter 2",
            "Chapter 3",
            "Chapter 4",
            "Chapter 5",
            "Chapter 6",
            "Chapter 7",
            "Chapter 8",
            "Chapter 9",
            "Chapter 10",
            "Chapter 11",
            "Chapter 12",
            "Chapter 13",
            "Chapter 14",
            "Chapter 15",
            "Chapter 16",
            "Chapter 17",
            "Chapter 18"
          ]
    }

    const onSelect = (_, value)=>{
        setChapter(config.options.indexOf(value))
    }

    return (
        <div className="bgt-root">
            <div className="bgt-cont">
                <Field config={config} fieldError={()=>false} onChange={onSelect}/>
                <div className="bgt-texts">
                    {
                        bg[chapter].map(text=>{
                            return (
                                <div className="bgt-text">
                                    <div className="bgt-verse">{text.verse}</div>
                                    <div className="bgt-trans">{text.trans}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default BGT