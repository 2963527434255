import styled, {css, keyframes} from "styled-components";

const loader = () => keyframes`
    from {
        width: 0%;
    }

    to {
        width: 100%;
    }
`

export const StlyedLoader = styled.div`

    height: inherit;
    background-color: var(--bar);
    border-radius: 100vw;

    ${props =>
        css`
            animation: ${loader} ${props.delay}s linear;
        `
    }

`