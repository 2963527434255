import React, { useEffect, useRef, useState } from 'react';
import "./index.css"
import Header from '../../components/custom/header';
import Assignee from './assignee';
import PageLoader from '../../components/info/pageloader';
import {useGlobal} from "../../context/global"
import ContactBubble from '../../components/holders/bubble/contact';

function Assignees(){

    const { auth, api } = useGlobal()

    var [ assignees, setAssignees ] = useState()
    var [ swipedAssignee, setSwipedAssignee ] = useState()
    var [ filter, setFilter ] = useState()
    var timerID = useRef()
 
    useEffect(()=>{
      const id = auth.user.id
      api.call('/get-assignees', { id }).then(res=>{
        setAssignees(res.data)
      }).catch((err)=>{
        console.log(err, err)
      })
    }, [])

    const handleAssigneeSwipe = (assignee)=>{
      setSwipedAssignee(assignee)
    }

    const clearSwipedAssignee = ()=>{
      setSwipedAssignee(null)
    }

    const handleInput = (e)=>{
      clearTimeout(timerID.current)
      timerID.current = setTimeout(()=>{
          setFilter(e.target.value)
      }, 300)
  }

    return (
        <div>
          <Header title={"Assignees"}/>
          <div className='assignees-main'>
          <input onChange={handleInput} className="assignees-filter" placeholder="Start typing user name..."/>
          {swipedAssignee?
            <ContactBubble black phone={swipedAssignee.phone} name={swipedAssignee.name.split(" ")[0]} close={clearSwipedAssignee}/>:null}
          {
            assignees?
              <div className='assignees-holder'>
                {
                  assignees.map(assignee=>{
                    return <Assignee assignee={assignee} onSwipe={handleAssigneeSwipe} hide={filter && !assignee.name.toLowerCase().includes(filter.toLowerCase())}/>
                  })
                }
              </div>
            :<PageLoader text={"Loading assignee list..."}/>
          }
          </div>
        </div>
    )
}


export default Assignees
