import "./index.css"
import moment from "moment"

const MemberStats = ({ member }) => {


    const stats = [
        {
          title: 'In FOLK since',
          value: mem => moment(mem.date).format('Do MMM YYYY')
        },
        {
          title: 'Total sessions',
          value: mem => `${mem.attendance || 0} sessions`
        },
        {
          title: 'Last Attended',
          value: mem => {
            const weekCount = Math.floor(moment().diff(moment(mem.lastAttended), 'days') / 7)
            return mem.lastAttended?(moment().diff(moment(mem.lastAttended), 'days') <= 7 ? `Last week session` : `${weekCount} week${weekCount>1?'s':''} ago`):'Never Attended'
          }
        },
        {
          title: 'Regularity',
          value: mem => `${mem.regularity || 0} %`
        },
        {
          title: 'Status',
          value: mem => mem.status
        }
      ]

    return <div className="mem-stats">
            {
              stats.map(stat => 
                <div className="mem-stat">
                  <div className="mem-stat-title">{stat.title}</div>
                  <div className="mem-stat-value">{stat.value(member)}</div>
                </div>
              )
            }
    </div>
}

export default MemberStats