import "./index.css"

const UserAgent = ()=>{

    const UAParser = require('ua-parser-js')
    const info = JSON.stringify(new UAParser().setUA(navigator.userAgent).getResult(), null, 2)

    return (
        <>
            <div className="user-agent-root">{info}</div>
        </>
    )
}

export default UserAgent