export const resolveValue = (f, values) => !!f && (typeof(f)==='function'?f(values):f)

export const fieldError = (config, value, values) => {
    const { mandatory, empty, test, emptyError } = config

    const mandatoryValue = resolveValue(mandatory, values)
    const isEmptyValue = (empty || []).indexOf(value)==-1 && !value
    const nonMandatoryEmptyValue = !mandatoryValue && isEmptyValue
    const testValue = (!nonMandatoryEmptyValue && test && test(value))

    return (mandatoryValue && isEmptyValue && (emptyError || 'This field cannot be empty!')) || testValue
}

const getPersistedData = (formName, key) => {

    var persistedData
    try {
        persistedData = JSON.parse(localStorage.getItem(formName)) || {}
    }catch {
        persistedData = {}
    }

    return persistedData[key]
}

export const getPersistedValues = (formName, persist) => persist?getPersistedData(formName, 'values') || {}:{}
export const getPersistedPage = (formName, persist) => persist?getPersistedData(formName, 'page') || 0:0
export const persistData = (formName, values, page) => {
    try {
        localStorage.setItem(formName, JSON.stringify({ values, page }))
    }catch(err){
        console.log(`Unable to persisit data: ${err}`)
    }
}
export const getPageValidity = (page, values) => page.fields.filter(cfg => cfg.condition?cfg.condition(values):true).reduce((v, cfg)=> v && !fieldError(cfg, values[cfg.name], values), true)