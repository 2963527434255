const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
const phoneRegex = /^\d{10}$/

const coerceToArray = e => Array.isArray(e)?e:[e]

const filterAccess = (a, u) => a.filter(b => (b.exact || Array.isArray(b.access))?coerceToArray(b.access).indexOf(u.roleIndex)!=-1:u.roleIndex<=(b.access||99999))

const statusColors = {
    Regular: '#8BC34A',
    Irregular: '#FFC107',
    Inactive: '#FF5252',
    New: '#3F51B5',
    Snoozed: '#795548',
    NA: 'grey'
}

const _ = {
    emailRegex,
    phoneRegex,
    statusColors,
    coerceToArray,
    filterAccess
}

export default _