import Glass from "../../custom/glass"
import "./index.css"

const Bubble = ({dark, children, onClose, bodyStyle})=>{
  return (
    <div className="bubble-main">
      <Glass dark={dark} onClick={onClose} opacity={.4}/>
      <div className="bubble-root">
        <div className="bubble-body" style={bodyStyle || {}}>{children}</div>
      </div>
    </div>
  )
}

export default Bubble