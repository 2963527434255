import { useEffect, useRef, useState } from "react"
import "./index.css"
import _ from "../../../_"
import Glass from "../../custom/glass"
import { useGlobal } from "../../../context/global"

const ContextMenu = ({config, close, orientation}) => {

    const ref = useRef()
    const { auth } = useGlobal()

    useEffect(()=>{
        ref.current && ref.current.focus()
    }, [])

    const handleBlur = ()=>{
        close && close()
    }

    const configList = _.filterAccess(config, auth.user)

    return (
        configList.length? <>
            <Glass opacity={0}/>
            <div ref={ref} className={`cm-root ${orientation}`} tabIndex={0} onBlur={handleBlur}>
                {
                    configList.map((c, i) => <div className={`cm-item ${configList.length-1==i?'last':''} ${c.className || ''}`} onClick={()=>{
                        c.onClick()
                        close()
                    }}>{c.name}</div>)
                }
            </div>
        </>:null
    )
}

export default ContextMenu

