import moment from 'moment'
import _ from '../../_'

const curYear = parseInt(moment().format("YYYY"))
const today = moment().format("YYYY-MM-DD")

export const getConfig = preachers =>
    [
        {
            section: 'Contact Details',
            fields: [
                {
                    title: "Full Name",
                    name: "name",
                    mandatory: true,
                    post: v => v && v.toNameCase()
                },
                {
                    title: "Phone Number",
                    name: "phone",
                    mandatory: true,
                    type: 'tel',
                    post: v => isNaN(v) ? v : v.toPhoneCase(),
                    test: v => v && !_.phoneRegex.test(v) ? 'Invalid phone number' : ''
                },
                {
                    title: {
                        user: "Do you have WhatsApp number?",
                        editor: "Does the volunteer have WhatsApp number?"
                    },
                    name: "hasWhatsApp",
                    mandatory: true,
                    type: 'bool',
                    empty: [false],
                    drop: true,
                    dropValue: values => !!values.whatsapp
                },
                {
                    title: "WhatsApp number",
                    name: "whatsapp",
                    mandatory: true,
                    type: 'tel',
                    post: v => isNaN(v) ? v : v.toPhoneCase(),
                    test: v => v && !_.phoneRegex.test(v) ? 'Invalid phone number' : '',
                    condition: values => values && values.hasWhatsApp
                },
                {
                    title: "Email ID",
                    name: "email",
                    type: 'email',
                    post: v => (v || '').toLowerCase(),
                    mandatory: values => values && !values.hasWhatsApp,
                    test: v => v && !_.emailRegex.test(v) ? 'Invalid Email ID' : ''
                },
                {
                    title: "Address",
                    name: "address",
                    type: 'para',
                    mandatory: true,
                    encode: true,
                },
                {
                    title: {
                        user: 'Have you taken initiation?',
                        editor: 'Has the volunteer taken initiation?'
                    },
                    name: 'hasInitiation',
                    type: 'bool',
                    options: ['Yes', 'No'],
                    dropValue: values => !!values.initiationName,
                    drop: true
                },
                {
                    title: {
                        user: 'Your Initiation-name',
                        editor: 'Initiation-name'
                    },
                    name: 'initiationName',
                    type: 'text',
                    mandatory: true,
                    post: v => v && v.toNameCase(),
                    condition: v => v.hasInitiation
                }
            ]
        },
        {
            section: "Basic Details",
            fields: [
                {
                    title: "Date of Birth",
                    name: "dob",
                    mandatory: true,
                    type: 'date',
                    min: moment().subtract(100, 'years').format('YYYY-MM-DD'),
                    max: moment().format('YYYY-MM-DD'),
                    test: v => v && (v <= today && v >= today.replace(curYear + '', (curYear - 100) + '')) ? '' : `Year must between ${today.replace(curYear + '', (curYear - 100) + '')} and ${today}`,
                    pre: v => moment(v, 'YYYY-MM-DD').format('Do MMM YYYY')
                },
                {
                    title: "Gender",
                    name: "gender",
                    mandatory: true,
                    type: 'bool',
                    options: ['Male', 'Female'],
                    empty: [false],
                },
                {
                    title: "Marital Status",
                    name: "married",
                    mandatory: true,
                    type: 'bool',
                    empty: [false],
                    options: ['Married', 'Unmarried']
                },
                {
                    title: values => values && (values.gender ? `Father's Name` : values.married ? `Husband's Name` : `Father's Name`),
                    name: "relativeName",
                    mandatory: true,
                    post: v => v && v.toNameCase(),
                    condition: values => values && (values.gender !== undefined && values.married != undefined)
                },
                {
                    title: values => values && (values.gender ? `Father's Phone` : values.married ? `Husband's Phone` : `Father's Phone`),
                    name: "relativePhone",
                    type: 'tel',
                    post: v => isNaN(v) ? v : v.toPhoneCase(),
                    test: v => v && !_.phoneRegex.test(v) ? 'Invalid phone number' : '',
                    condition: values => values && (values.gender !== undefined && values.married != undefined)
                },
                {
                    title: values => values && (values.gender ? `Father's Profession` : values.married ? `Husband's Profession` : `Father's Profession`),
                    name: "relativeProfession",
                    encode: true,
                    mandatory: true,
                    condition: values => values && (values.gender !== undefined && values.married != undefined)
                },
                {
                    title: "Native",
                    name: "native",
                    encode: true,
                    mandatory: true,
                    type: 'text'
                },
                {
                    title: {
                        user: "Hobbies (if any)",
                        editor: "Hobbies"
                    },
                    encode: true,
                    name: "hobbies",
                    type: 'para'
                },
                {
                    title: {
                        user: "Skills (if any)",
                        editor: "Skills"
                    },
                    encode: true,
                    name: "skills",
                    type: 'para'
                },
                {
                    title: {
                        user: "Achievements (if any)",
                        editor: "Achievements"
                    },
                    encode: true,
                    name: "achievements",
                    type: 'para'
                }
            ]
        },
        {
            section: "Occupational Details",
            fields: [
                {
                    title: "Occupational Status",
                    name: "occupationalStatus",
                    type: 'select',
                    mandatory: true,
                    options: ['Student', 'Working', 'Seeking Job', 'Home-Maker', 'Retired']
                },

                {
                    title: "Institution",
                    encode: true,
                    name: "institution",
                    mandatory: values => values && ['Student', 'Seeking Job'].indexOf(values.occupationalStatus) != -1,
                    desc: values => values ? `Mention name of the ${values.occupationalStatus == 'Student' ? "school/college" : "institution"} where you ${values.occupationalStatus == 'Student' ? "are studying" : "last studied in"}` : '',
                    condition: values => values && (['Student', 'Seeking Job', 'Home-Maker'].indexOf(values.occupationalStatus) != -1)
                },
                {
                    title: "Course",
                    encode: true,
                    name: "course",
                    mandatory: values => values && ['Student', 'Seeking Job'].indexOf(values.occupationalStatus) != -1,
                    condition: values => values && (['Student', 'Seeking Job', 'Home-Maker'].indexOf(values.occupationalStatus) != -1)
                },
                {
                    title: values => values ? (values.occupationalStatus == 'Student' ? "Year of Joining" : "Year of Course Completion") : "Year",
                    name: "studyYear",
                    mandatory: values => values && ['Student', 'Seeking Job'].indexOf(values.occupationalStatus) != -1,
                    desc: 'Mention the year in which you joined the above mentioned course',
                    type: 'number',
                    max: curYear,
                    min: curYear - 80,
                    condition: values => values && (['Student', 'Seeking Job', 'Home-Maker'].indexOf(values.occupationalStatus) != -1),
                    test: v => v && (v <= curYear && v >= curYear - 80) ? '' : `Year must between ${curYear - 80} and ${curYear}`
                },

                {
                    title: "Organization",
                    name: "organization",
                    encode: true,
                    mandatory: true,
                    desc: values => `Mention name of the company/business/organization you ${values.occupationalStatus == 'Working' ? 'are working' : 'last worked'} for`,
                    condition: values => values && (values.occupationalStatus == 'Working' || values.occupationalStatus == 'Retired'),
                },
                {
                    title: "Designation",
                    name: "designation",
                    encode: true,
                    mandatory: true,
                    desc: 'Mention your title/role of your work',
                    condition: values => values && (values.occupationalStatus == 'Working' || values.occupationalStatus == 'Retired'),
                },
                {
                    title: "Highest Qualification",
                    name: "highestQualification",
                    encode: true,
                    mandatory: true,
                    desc: 'Mention your last educational qualification',
                    condition: values => values && (values.occupationalStatus == 'Working' || values.occupationalStatus == 'Retired')
                }
            ]
        },
        {
            section: {
                user: 'Your association with ISKCON',
                editor: `Volunteer's association with ISKCON`
            },
            fields: [
                {
                    title: {
                        user: "When did you come in touch with ISKCON?",
                        editor: "When did the volunteer come in touch with ISKCON?"
                    },
                    name: "touch",
                    mandatory: true,
                    type: 'date',
                    desc: 'Mention month and year',
                    min: moment().subtract(100, 'years').format('YYYY-MM-DD'),
                    max: moment().format('YYYY-MM-DD'),
                    test: v => v && (v <= today && v >= today.replace(curYear + '', (curYear - 100) + '')) ? '' : `Year must between ${today.replace(curYear + '', (curYear - 100) + '')} and ${today}`,
                    pre: v => moment(v, 'YYYY-MM-DD').format('Do MMM YYYY')
                },
                {
                    title: {
                        user: "How many rounds of Hare Krishna Maha Mantra do you chant daily?",
                        editor: "Japa Rounds"
                    },
                    name: "rounds",
                    mandatory: true,
                    type: 'select',
                    options: Array.from(Array(17).keys()),
                    empty: [0]
                },
                {
                    title: {
                        user: "Which of the below devotees are you in touch with?",
                        editor: "Preacher"
                    },
                    name: "preacher",
                    mandatory: true,
                    type: 'select',
                    options: preachers.map(d => {
                        return {
                            name: d.name,
                            value: d.id
                        }
                    }),
                    empty: [null]
                }
            ]
        }
    ]

export const getDescription = member => {

    switch (member.occupationalStatus) {
        case 'Student':
            return `Studying ${member.course || ""} ${member.institution ? "at " : ""}${member.institution || ""}`.trim()
        case 'Working':
            return `${member.designation || "Working"} ${member.organization ? "at " : ""}${member.organization || ""}`.trim()
        case 'Seeking Job':
            return `Seeking job, studied${member.course ? ` ${member.course} ` : " "}${member.institution ? "from " : ""}${member.institution || ""}`.trim()
        default:
            return 'FOLK Member'
    }
}

export const encode = value => value ? btoa(value) : null
export const decode = value => value ? atob(value) : null

export const getPayload = (payload, formData, update) => {
    getConfig([]).forEach(section => {
        section.fields.forEach(config => {
            if (config.encode) {
                if (!(update && formData[config.name] === undefined)) {
                    payload[config.name] = encode(formData[config.name])
                }
            }
            if (config.drop) {
                delete payload[config.name]
            }
        })
    })
    return payload
}

export const reversePayload = (values) => {
    values = values || {}
    var payload = { ...values }
    getConfig([]).forEach(section => {
        section.fields.forEach(config => {
            if (config.encode) {
                payload[config.name] = decode(values[config.name])
            }
            if (config.drop) {
                payload[config.name] = config.dropValue(values)
            }
            if (config.type == 'bool') {
                payload[config.name] = !!payload[config.name]
            }
        })
    })
    return payload
}
