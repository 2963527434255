import { useRef } from "react"
import "./index.css"

const Search = props => {

    const { placeholder, onSearch, delay, width } = props

    const id = useRef()
    const handleChange = e => {
        clearTimeout(id.current)
        id.current = setTimeout(()=>{
            onSearch && onSearch(e.target.value)
        }, delay || 1000)
    }

    return <div className="sea-root">
        <input className="sea-in" style={width && {width}} placeholder={placeholder} onChange={handleChange}/>
    </div>

}

export default Search