import "./index.css"
import DP from '../../../components/custom/dp';
import Icon from '../../../components/custom/icon';
import Swipe from '../../../components/holders/swipe';
import moment from 'moment';
import Accordian from '../../../components/holders/accordian'
import MemberStats from '../../components/member-stats'
import { useCallback, useState } from "react";
import _ from "../../../_"

const Assignee = ({assignee, onSwipe, hide})=>{

  const handleSwipe = ()=>{
    onSwipe(assignee)
  }

  var [ showStats, setShowStats ] = useState()
  const toggleStats = useCallback(()=>{
    setShowStats(!showStats)
  }, [showStats])

  const isBuddyCoord = assignee.roleID == 'buddy-coord'

  return (
    <Swipe right={{color: _.statusColors[assignee.status], padWidth: 1, minDrag:10, maxDrag: 30, onSwipe: handleSwipe}} hide={hide}>
        <div className='assignee-box' key={assignee.name}>

          <div className='assignee-content'>

            <div className='assignee-dp-holder'>
              <DP user={assignee} size={12} className={"assignee-dp"} badge={
                assignee.roleID=="buddy-coord"?{
                  color: 'var(--bar)',
                  icon: {
                    name: "group",
                    color: "#fff"
                  }
                }:null
              } enlarge/>
            </div>

            <div className='assignee-details-cont' onClick={toggleStats}>

              <div className='assignee-details'>
                <div className='assignee-name'>{assignee.name}</div>
                <div className='assignee-buddy'>
                  {!isBuddyCoord && <Icon name="group" size={5} color="#555"/>}
                  <div className='assignee-buddy-name'>{assignee.buddyName || (isBuddyCoord?"Buddy Coordinator":"-")}</div>
                </div>
              </div>

              <div className='assignee-right-info'>
                <div className='assignee-course'>{assignee.courseID}</div>
                <div className='assignee-notif'>
                  {
                    assignee.dob && assignee.dob.dobDays()<=7?
                      <div className='assignee-notif-blob'>
                        <Icon className="assignee-notif-icon" name="cake" color="white" size={5.5}/>
                        <div>{moment(assignee.dob).format("DD MMM")}</div>
                      </div>
                    :null
                  }
                  {
                    assignee.snooze && assignee.snooze.snoozeDays()?
                        <div className='assignee-notif-blob'>
                        <Icon className="assignee-notif-icon" name="snooze" color="white" size={5.5}/>
                        <div>{moment(assignee.snooze).format("DD MMM")}</div>
                      </div>
                    :null
                  }
                </div>
                <div/>
              </div>
              
            </div>
          </div>

          <Accordian show={showStats} maxHeight="30vw">
            <MemberStats member={assignee}/>
          </Accordian>
        </div>
    </Swipe>

  )
 
}


export default Assignee
