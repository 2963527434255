const openDB = (dbName, storeName) => {
  return new Promise((resolve, reject) => {
      const request = indexedDB.open(dbName, 1)

      request.onupgradeneeded = (event) => {
          const db = event.target.result
          if (!db.objectStoreNames.contains(storeName)) {
              db.createObjectStore(storeName, { keyPath: 'id' })
          }
      }

      request.onsuccess = () => {
          resolve(request.result)
      }

      request.onerror = () => {
          reject(request.error)
      }
  })
}

const get = async (dbName, storeName, key) => {
  try {
      const db = await openDB(dbName, storeName)
      return new Promise((resolve, reject) => {
          const transaction = db.transaction([storeName], 'readonly')
          const store = transaction.objectStore(storeName)
          const request = store.get(key)

          request.onsuccess = () => {
              resolve(request.result ? request.result.data : null)
          }

          request.onerror = () => {
              reject(null)
          }
      })
  } catch (error) {
      return null
  }
}

const set = async (dbName, storeName, key, storeData) => {
  try {
      const db = await openDB(dbName, storeName)
      return new Promise((resolve, reject) => {
          const transaction = db.transaction([storeName], 'readwrite')
          const store = transaction.objectStore(storeName)
          const request = store.put({ id: key, data: storeData })

          request.onsuccess = () => {
              resolve(true)
          }

          request.onerror = () => {
              reject(false)
          }
      })
  } catch (error) {
      return false
  }
}

const clear = async (dbName, storeName, key) => {
  try {
      const db = await openDB(dbName, storeName)
      return new Promise((resolve, reject) => {
          const transaction = db.transaction([storeName], 'readwrite')
          const store = transaction.objectStore(storeName)
          const request = store.delete(key)

          request.onsuccess = () => {
              resolve(true)
          }

          request.onerror = () => {
              reject(false)
          }
      })
  } catch (error) {
      return false
  }
}

const indexed = {
  get,
  set,
  clear
}

export default indexed
