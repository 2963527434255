import "./index.css"
import Icon from "../../../../components/custom/icon"

const ConstructionPage = ()=>{
    return (
        <>
        <Icon name="engineering" size={25} color="#555"/>
        <div>
            <div className='constr-text'>{"This app is still under construction. Yet you can explore the existing features by clicking on the menu icon or your profile picture above in the header section."}</div>
        </div>
        </>
    )
}

export default ConstructionPage