import "./index.css"
import Icon from '../../../../custom/icon';
import {  useEffect, useState } from "react";
import Options from "../../../input/options";

const Dropdown = props => {
    const { onEditComplete, onFocusChange, options, def } = props
    const defaultValue = options.indexOf(def)!=-1?def:(options.filter(o=>o.value===def)[0] || {}).name

    var [ name, setName ] = useState(defaultValue)
    var [ focus, setFocus ] = useState()
    var [ showOptions, setShowOptions ] = useState()

    // post-process value after blurred and orward focus event to parent component
    useEffect(() => {
        if(focus!==undefined){
            onFocusChange && onFocusChange(focus)
            focus && setShowOptions(true)
        }
    }, [focus])

    const handleFocus = () => setFocus(true)
    const handleBlur = () => setFocus(false)

    const onSelect = (name, value) => {
        setName(name)
        onEditComplete && onEditComplete(value)
        setShowOptions(false)
    }

    return (
        <>
            <div className={`dropdown-root`}>
                <div
                    tabIndex={0}
                    className={`dropdown-field`}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                >{name}</div>              
                <Icon className="dropdown-icon" name="arrow-drop-down" color="#888" size={4.5}/>
            </div>

            {showOptions && <Options options={options} onSelect={onSelect} close={()=>setShowOptions(false)}/>}
        </>
    )
}

export default Dropdown