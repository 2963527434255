import axios from 'axios'

class API {

  constructor(storeData) {
    this.instance = axios.create()
    this.instance.interceptors.request.use((config) => {
      if (storeData) {
        if (storeData.users && storeData.users.length) {
          config.headers.user = storeData.users[storeData.index].name
          config.headers.userID = storeData.users[storeData.index].id
          config.headers.roleID = storeData.users[storeData.index].roleID
        }
      }
      return config
    }, (error) => {
      console.log("Could not initiate API reuest interceptor:", error)
    })
  }

  async call(endpoint, body) {
    return this.instance.post('/api', body, { headers: { endpoint } })
  }
}


export default API