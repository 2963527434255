import styled, { css, keyframes } from 'styled-components'

const glassFade = opacity => keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: ${opacity!==undefined?opacity:0.6};
    }
`

export const StyledGlass = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    cursor: pointer;
    top: 0;
    left: 0;

    ${props => css`

        opacity: ${props.opacity!==undefined?props.opacity:0.6};
        background-color: ${props.dark?"black":"white"};
        animation: ${glassFade(props.opacity)} ${props.delay || .3}s ease-in-out;
        z-index: ${props.z || 99}
    `}

`