import './index.css'

const Spinner = ({style, text, size, className})=>{
  size = size || 4
  return (
    <div className={`spinner-main ${className}`}>
    <div
      className={`spinner-bg-circle`}
      style={{...{width:`${size}vw`, height:`${size}vw`},...style}}>
    </div>
    {text?<div className='spinner-load-text' style={{ fontSize: `${size}vw` }}>{text}</div>:null}
    </div>
  );
}

export default Spinner;
