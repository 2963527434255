import React, { createContext, useContext, useState } from 'react'
import PageLoader from '../../components/info/pageloader'

const GlobalContext = createContext()

export const GlobalProvider = ({ storeData, apiClient, children }) => {

  var [showLoader, setShowLoader] = useState(false)
  var [loaderText, setLoaderText] = useState('')

  const auth = storeData ? {
    store: storeData,
    user: storeData.users[storeData.index],
    users: storeData.users,
    index: storeData.index
  } : null

  const UAParser = require('ua-parser-js')
  const info = new UAParser().setUA(navigator.userAgent).getResult()

  const deviceInfo = {
    isMobileDevice: info.os.name == "Android" || info.os.name == "iOS",
    os: info.os.name,
    browser: info.browser.name,
    isChrome: info.browser.name == "Chrome",
    isDev: window.location.hostname == "localhost",
    appInstalled: !!window.matchMedia('(display-mode: standalone)').matches,
  }

  const api = apiClient

  const pageLoader = {
    show: text => {
      setLoaderText(text)
      setShowLoader(true)
    },
    hide: () => { setShowLoader(false) }
  }

  return (
    <GlobalContext.Provider value={{ auth, deviceInfo, api, pageLoader }}>
      <>
        {showLoader && <PageLoader block text={loaderText} />}
        {children}
      </>
    </GlobalContext.Provider>
  )
}

export const useGlobal = () => {
  return useContext(GlobalContext)
}
