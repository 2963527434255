import "./index.css"
import moment from "moment"
import { toast } from "react-toastify";
import Header from "../../components/custom/header"
import { useEffect, useRef, useState } from "react"
import { useGlobal } from "../../context/global"
import PageLoader from "../../components/info/pageloader";
import DP from "../../components/custom/dp";
import Icon from "../../components/custom/icon";
import Input from "../../components/custom/input";

const Gifts = props => {

    const {api} = useGlobal()
    var [ date, setDate ] = useState()
    var [ stockGift, setStockGift ] = useState()
    var [ stockCert, setStockCert ] = useState()
    var [ stockShirt, setStockShirt ] = useState()
    var [ expGift, setExpGift ] = useState()
    var [ expCert, setExpCert ] = useState()
    var [ expShirt, setExpShirt ] = useState()
    var [ actualGift, setActualGift ] = useState()
    var [ actualCert, setActualCert ] = useState()
    var [ actualShirt, setActualShirt ] = useState()
    var [ announcementList, setAnnouncementList ] = useState()
    var [ reportLink, setReportLink ] = useState()

    const dist = useRef({})
    const getReportLink = () => `https://wa.me/916364903722?text=${encodeURIComponent(Object.keys(dist.current).map(id => `${dist.current[id].participant.name}\t${dist.current[id].participant.phone}\t${dist.current[id].g?'g':''}${dist.current[id].c?'c':''}`).join('\n')+`\n\nT-Shirt: ${Object.keys(dist.current).map(id => dist.current[id].c?dist.current[id].participant.tShirtSize:'').sort().join('')}`)}
    `.trim() 

    useEffect(()=>{
        api.call('/get-gifts').then(res => {
            const [ 
                d,
                sg, sc, ss,
                eg, ec, es,
                ag, ac, as,
                a ] = res.data
            setStockGift(sg[0].count)
            setStockCert(sc[0].count)
            setStockShirt(ss)

            setExpGift(eg[0].count)
            setExpCert(ec[0].count)
            setExpShirt(es)

            setActualGift(ag[0].count)
            setActualCert(ac[0].count)
            setActualShirt(as)

            setAnnouncementList(a)

            setDate(d[0].date)
        }).catch(e => {
            toast.error(`${e}`)
        })
    }, [])

    return (
        <div>            
            <Header title={'Gifts'}/>
            {date===undefined ?
                <PageLoader text="Loading gifts data..."/>:
                <div className="gifts-root">

                    <div className="gifts-stats-main">
                        <div className="gifts-stats-main-label">Stock Requirement</div>
                        <div className="gifts-stats-values">
                            <div className="gifts-stats-value">
                                <div className="gifts-stats-label">Gifts</div>
                                <div className="gifts-stats-num">{stockGift}</div>
                            </div>
                            <div className="gifts-stats-value">
                                <div className="gifts-stats-label">Certificates</div>
                                <div className="gifts-stats-num">{stockCert}</div>
                            </div>
                            <div className="gifts-stats-value">
                                <div className="gifts-stats-label">T-Shirts</div>
                                <div className="gifts-stats-num shirt">{stockCert}</div>
                                <div className="gifts-stats-shirt">{stockShirt.map(s=>`${s.shirt}:${s.count}`).join(' ')}</div>
                            </div>
                        </div>
                    </div>

                    <hr style={{opacity: 0}}/>

                    <div className="gifts-stats-main">
                        <div className="gifts-stats-main-label">Expected Requirement</div>
                        <div className="gifts-stats-values">
                            <div className="gifts-stats-value">
                                <div className="gifts-stats-label">Gifts</div>
                                <div className="gifts-stats-num">{expGift}</div>
                            </div>
                            <div className="gifts-stats-value">
                                <div className="gifts-stats-label">Certificates</div>
                                <div className="gifts-stats-num">{expCert}</div>
                            </div>
                            <div className="gifts-stats-value">
                                <div className="gifts-stats-label">T-Shirts</div>
                                <div className="gifts-stats-num shirt">{expCert}</div>
                                <div className="gifts-stats-shirt">{expShirt.map(s=>`${s.shirt}:${s.count}`).join(' ')}</div>
                            </div>

                        </div>
                    </div>

                    <hr style={{opacity: 0}}/>

                    <div className="gifts-stats-main">
                        <div className="gifts-stats-main-label">{`Actual Requirement (for ${moment(date, 'YYYY-MM-DD').format("Do MMM 'YY")})`}</div>
                        <div className="gifts-stats-values">
                            <div className="gifts-stats-value">
                                <div className="gifts-stats-label">Gifts</div>
                                <div className="gifts-stats-num">{actualGift}</div>
                            </div>
                            <div className="gifts-stats-value">
                                <div className="gifts-stats-label">Certificates</div>
                                <div className="gifts-stats-num">{actualCert}</div>
                            </div>
                            <div className="gifts-stats-value">
                                <div className="gifts-stats-label">T-Shirts</div>
                                <div className="gifts-stats-num shirt">{actualCert}</div>
                                <div className="gifts-stats-shirt">{actualShirt.map(s=>`${s.shirt}:${s.count}`).join(' ')}</div>
                            </div>

                        </div>
                    </div>                    

                    <hr style={{opacity: 0}}/>

                    <div>
                        <div className="gifts-heading">{`Certificate Names & T-Shirt Sizes`}</div>
                        <div style={{margin: '2vw 0vw 0vw 5vw'}}>
                            {
                                announcementList.filter(a => a.cert && a.sessions>=6).map((participant, i) =>
                                    <div style={{margin: '1vw'}}>{`${i+1}. ${participant.name} (${participant.tShirtSize})`}</div>
                                )
                            }
                        </div>
                    </div>                    

                    <div>
                        <div className="gifts-heading">{`Announcement List (${announcementList.length}):`}</div>
                        <div>
                            {
                                announcementList.map(participant => 
                                    <div className="gifts-people">
                                        <div style={{display: 'flex'}}>
                                            <DP className={'gifts-dp'} user={participant} size={15} enlarge/>
                                            <div className="gifts-people-body" style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                                <div>
                                                    <div style={{fontWeight: '900'}}>{participant.name}</div>
                                                    <div>{`${participant.sessions} sessions`}</div>
                                                </div>
                                                <div style={{marginRight: '2vw', width: '17.5vw', display: 'flex', justifyContent: 'space-between'}}>
                                                    <a target="_blank" href={`tel:+91${participant.phone}`}><Icon color='var(--green)' name='call'/></a>
                                                    <a target="_blank" href={`https://wa.me/91${participant.phone}`}><Icon color='var(--green)' name='whatsapp'/></a>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="gifts-report">
                                            {participant.gift?
                                                <div style={{display: 'flex', marginRight: '3vw'}}>
                                                    <Input type='checkbox' onEditComplete={v => {
                                                        dist.current[participant.id] = dist.current[participant.id] || {participant}
                                                        dist.current[participant.id].g = v
                                                        setReportLink(getReportLink)
                                                    }} label='Japa Kit'/>
                                                </div>
                                            :null}

                                            {participant.cert?
                                                <div style={{display: 'flex', marginRight: '3vw'}}>
                                                    <Input type='checkbox' onEditComplete={v => {
                                                        dist.current[participant.id] = dist.current[participant.id] || {participant}
                                                        dist.current[participant.id].c = v
                                                        setReportLink(getReportLink)
                                                    }} label={`Certificate & T-Shirt (${participant.tShirtSize})`}/>
                                                </div>
                                            :null}
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>



                    <a className="gifts-share" target="_blank" href={reportLink}>Share Report</a>

                </div>
            }
            
        </div>
    )
}

export default Gifts