import { useCallback, useState, useEffect } from "react"
import "./index.css"
import Cropper from 'react-easy-crop'
import Icon from "../icon"
import { useGlobal } from "../../../context/global"

export const useCropLoader = (minPhotoSize, defaultCropped)=>{

    const { api } = useGlobal()

    var [ selected, setSelected ] = useState()
    var [ cropped, setCropped ] = useState(defaultCropped)

    const select = () => {
        minPhotoSize = minPhotoSize || 800
        var fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.style.display = 'none';
        document.body.appendChild(fileInput)
        fileInput.click()
        fileInput.addEventListener('change', e => {
            if(!e.target.files){
                return
            }
            const reader = new FileReader()
            reader.onload = (e) => {
                var img = new Image()
                img.onload = ()=>{
                    var min = img.naturalHeight>=img.naturalWidth?img.naturalWidth:img.naturalHeight
                    if(min>=minPhotoSize){
                        setSelected(e.target.result)
                        document.body.removeChild(fileInput)
                    }else{
                        alert(`This image is small in size. Please select an image that is atleast 800 pixels in width and height.`)
                    }
                }
                img.src = e.target.result
            }
            reader.readAsDataURL(e.target.files[0])
        })
    }

    const crop = cropValue => {

        const canvas = document.createElement('canvas')
        canvas.width = cropValue.width
        canvas.height = cropValue.height
        const ctx = canvas.getContext('2d')

        var img = new Image()
        img.onload = ()=>{
            ctx.drawImage(img, -cropValue.x, -cropValue.y)

            const scaledCanvas = document.createElement('canvas')
            scaledCanvas.width = minPhotoSize
            scaledCanvas.height = minPhotoSize
            const scaledCtx = scaledCanvas.getContext('2d')
            scaledCtx.drawImage(canvas, 0, 0, minPhotoSize, minPhotoSize)

            setCropped(scaledCanvas.toDataURL('image/jpeg', 0.5))
            setSelected(null)
        }
        img.src = selected
    }

    const upload = useCallback((endpoint, id) => api.call(endpoint, { id, imageDataURL: cropped, filename: `dp/${id}.jpeg` }), [cropped])

    return { select, crop, upload, setSelected, setCropped, selected, cropped }
}

export const CropLoader = ({src, minSize, onComplete, onAbort}) => {

    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    var [ cropValue, setCropValue ] = useState()
    var [ imageSize, setImageSize ] = useState()

    useEffect(()=>{
        const img = new Image()
        img.onload = () => {
            setImageSize({
                width: img.naturalWidth,
                height: img.naturalHeight,
                min: img.naturalHeight>=img.naturalWidth?img.naturalWidth:img.naturalHeight
            })
        }
        img.src = src
    }, [src])
    
    const onCropChange = useCallback((crop) => {
        setCrop(crop)
    }, [])
    
    const onZoomChange = useCallback((zoom) => {
        setZoom(zoom)
    }, [imageSize])

    const onCropValueChange = useCallback((cropValue) => {
        setCropValue(cropValue)
    }, [])

    const onDone = ()=>{
        onComplete({
            x: imageSize.width * (cropValue.x/100),
            y: imageSize.height * (cropValue.y/100),
            width: imageSize.width * (cropValue.width/100),
            height: imageSize.height * (cropValue.height/100)
        })
    }

    return (
        <div>
            <div className="imgcropup-crop-head">
                <div className="imgcropup-crop-head-left">
                    <Icon className="imgcropup-crop-head-done" onClick={onAbort} color="#fff" name="close"/>
                    <div className="imgcropup-label">{"Crop Photo"}</div>
                </div>
                <div className="imgcropup-crop-head-right">
                    <Icon className="imgcropup-crop-head-done" onClick={onDone} color="#fff" name="done"/>
                </div>
            </div>
            <Cropper
                image={src}
                crop={crop}
                zoom={zoom}
                onCropChange={onCropChange}
                onZoomChange={onZoomChange}
                aspect={1}
                onCropComplete={onCropValueChange}
                cropShape="round"
                zoomSpeed={0.1}
                maxZoom={imageSize?imageSize.min/minSize:3}
                style={
                    {
                        containerStyle: {
                            zIndex: 998
                        }
                    }
                }
            />
        </div>
    )
}