import { useCallback, useEffect, useRef, useState } from "react"
import Header from "../../components/custom/header"
import "./index.css"
import User from "./user"
import Bubble from "../../components/holders/bubble"
import store from "../../store"
import { toast } from "react-toastify"
import { useGlobal } from '../../context/global'

const Users = ()=>{

    const { auth, api } = useGlobal()

    var [ users, setUsers ] = useState()
    var [ filter, setFilter ] = useState()
    var [ showUserActions, setShowUserActions ] = useState(false)
    var [ selectedUser, setSelectedUser ] = useState()

    var timerID = useRef()

    useEffect(()=>{
        api.call('/get-users').then(r=>{
            setUsers(r.data)
        }).catch(err=>{
            console.log(err)
        })
    }, [])

    const handleInput = (e)=>{
        clearTimeout(timerID.current)
        timerID.current = setTimeout(()=>{
            setFilter(e.target.value)
        }, 300)
    }

    const handleUserClick = (user)=>{
        setSelectedUser(user)
        setShowUserActions(true)
    }

    const hideUserActions = ()=>{
        setShowUserActions(false)
    }

    const handleCustomLogin = ()=>{
        const newUser = users.filter(user=>{
            return user.id == selectedUser.id && user.roleID == selectedUser.roleID
        })[0]

        if(newUser && (newUser.id != auth.user.id || newUser.roleID != auth.user.roleID) ){
            const newUsers = [...auth.users, newUser]
            store.set({
                index: newUsers.length-1,
                users: newUsers
            }).then(()=>{window.open("/home", "_self")}).catch(error => {toast.error(error)})
            
        }else{
            toast.warn("You are already logged in as the same user!")
        }
        
    }

    return (
        <div className="users-root">
            <Header title={"Users"}/>
            <div className="users-main">
                {showUserActions?
                    <Bubble onClose={hideUserActions}>
                        <div className="users-actions">
                            <div className="users-action" onClick={handleCustomLogin}>{`Login As ${selectedUser.name}`}</div>
                        </div>
                    </Bubble>
                :null}
                <input onChange={handleInput} className="users-filter" placeholder="Start typing user name..."/>
                {users?<div>
                    {
                        users.map(user=>{
                            return (
                                <User
                                    user={user}
                                    hide={filter && !user.name.toLowerCase().includes(filter.toLowerCase())}
                                    onClick={handleUserClick}
                                />
                            )
                        })
                    }
                </div>:null}
            </div>
        </div>
    )
}

export default Users