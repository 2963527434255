import React from 'react'
import "./index.css"
import Header from '../../components/custom/header'
import { useNavigate } from "react-router-dom"
import { useGlobal } from '../../context/global'
import ConstructionPage from './homes/construction'
import FOLKMemberHome from './homes/folkmember'

function Home(){
  const navigate = useNavigate()
  const {auth} = useGlobal()
  const { user } = auth

  const HomeContent = () =>{
    switch(user.roleID){
      case "folk-member":
        return <FOLKMemberHome/>
      default:
        return <ConstructionPage/>
    }
  }

  return (
      <div>
        <Header/>
        <div className='home-main'>
          <HomeContent/>
        </div>
        </div>
    ) 
}


export default Home
