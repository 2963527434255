import { useState } from 'react'
import './index.css'
import Input from '../../input'
import { fieldError } from '../util'

const resolveValue = (f, values, user) => !!f && (typeof(f)==='function'?f(values):(f[user] || f))

const Field = props => {

  const { config, onEditComplete, values, def, user, viewOnly, placeholder } = props
  const { name, title, type, min, max, mandatory, desc, post, options, empty } = config

  var mandatoryValue = resolveValue(mandatory, values, user)
  var titleValue = resolveValue(title, values, user)
  var descValue = resolveValue(desc, values, user)

  var [ error, setError ] = useState(!viewOnly && def!==undefined && fieldError && fieldError(config, def, values))

  const handleEditComplete = value => {
    const e = fieldError && fieldError(config, value, values)
    onEditComplete && onEditComplete(name, value)
    setError(e)
  }

  // clear error when focused
  const onFocusChange = f => f && setError('')

  return (
    <div className='field-main'>

      {/* Label block */}
      {titleValue && <div className='field-label-holder'>
        <div className={`field-label ${viewOnly?'view-only':''}`}>{titleValue}</div>
        {!viewOnly && <div className='field-mand'>{mandatoryValue?' *':''}</div>}
      </div>}
      
      {/* Description block */}
      {!viewOnly && descValue && <div className='field-desc'>{descValue}</div>}

      {/* Input box block */}
      <div className={`field-cont ${viewOnly?'view-only':''} ${error && 'error'} ${type=='checkbox'?'noborder':''}`}>
        <Input
          key={name}
          type={type}
          min={min}
          max={max}
          onEditComplete={handleEditComplete}
          onFocusChange={onFocusChange}
          post={post}
          empty={empty}
          options={options}
          def={def}
          viewOnly={viewOnly}
        />
      </div>
      
      {/* Warning block */}
      {(!viewOnly && error) && <div className='field-error'>{error}</div>}
    </div>
  )
}

export default Field
