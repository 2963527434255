import { useGlobal } from "../../context/global";
import "./index.css"
import { useCallback, useEffect, useState } from 'react'
import moment from 'moment'
import clipboardy from 'clipboardy'
import { toast } from "react-toastify";

const Catchup = () => {

    const { api } = useGlobal()

    var [catchupData, setCatchupData] = useState([])

    const formMessage = c => `https://web.whatsapp.com/send?name=${encodeURIComponent(c.name)}&phone=91${c.phone}&text=${encodeURIComponent(c.message)}`

    useEffect(()=>{
        api.call('/get-catchup-data').then(res=>{
            const [ community, participation, classes, calendar ] = res.data

            // console.log(res.data)

            const catchupCodes = classes.map(c=>c.code)

            const getMissedClasses = (folkMember)=>{
                const classList = calendar.filter(cal=>cal.date>=folkMember.date).map(cal=>cal.code)
                const classAtt = participation.filter(par=>par.id==folkMember.id && par.attendance).map(par=>par.code)
                const totalMiss = classList.filter(listCode=>classAtt.indexOf(listCode)==-1).unique()
                return catchupCodes.filter(c=>totalMiss.indexOf(c)!=-1)
            }

            const getMessage = (cdata)=>{
                const sing = cdata.catchup.length==1
                const date = cdata.catchup[0].date
                return `
🏃🏻 Reminder for Catch-up Classes ℹ️

Hare Krishna ${cdata.name.split(" ")[0]} 🙏

This is a reminder of your upcoming catch-up classes. You had previously missed ${cdata.catchup.length} class${sing?"":"es"} in this cycle of SOS. You can attend catch-up classes for ${sing?"this":"these"} topic${sing?"":"s"} on coming *${moment(date).format("dddd, Do MMM YYYY")}*, as per the below-mentioned schedule 🕐:

${cdata.catchup.map((c, i)=>{
    return `${i+1}. *${c.startTime} - ${c.endTime}*: ${c.name}`
}).join(`
`)}

📍 Venue: AV Hall

If you find any discrepancy in above mentioned data or if you have any concerns, please bring it to our notice.

Regards,
FOLK Mysore`.trim()
            }

            const getAttendance = (folkMember) => {
                return calendar.filter(cal=>cal.date>=folkMember.date).map(cal=>{
                    return {
                        ...cal,
                        attendance: !!participation.filter(par=>par.eventID==cal.eventID && par.id==folkMember.id).map(par=>par.attendance)[0]
                    }
                })
            }

            setCatchupData(
                community.filter(folkMember=>{
                    return getMissedClasses(folkMember).length
                }).map(folkMember=>{
                    return {
                        name: folkMember.name,
                        phone: folkMember.phone,
                        catchup: getMissedClasses(folkMember).map(c=>classes.filter(cc=>c==cc.code)[0]),
                        attendance: getAttendance(folkMember)
                    }
                }).map(cdata=>{
                    return {
                        name: cdata.name,
                        phone: cdata.phone,
                        message: getMessage(cdata)
                    }
                }).sort((c1, c2)=>{
                    return c1.name>c2.name?1:-1
                })
            )
        })
    }, [])

    const handleLinkCopy = useCallback(()=>{
        clipboardy.write(catchupData.map(formMessage).join("\n")).then(()=>{
            toast.info("Links are copied to clipboard!")
        })
    }, [catchupData])

    return (
        <div>
            <div>{`${catchupData.length} community may attend catch-up classes`}</div>
            <div onClick={handleLinkCopy} className="catchup-copy">Copy Links</div>
        </div>
    )
}

export default Catchup