import DP from "../../../components/custom/dp"
import "./index.css"

const User = ({user, hide, onClick})=>{

    const handleUserClick = ()=>{
        onClick(user)
    }
    
    return (
        <div className="user-root" style={{display: hide?"none":null}}>
            <div className="user-left">
                <DP user={user} size={15} className="user-dp" enlarge/>
                <div className="user-details" onClick={handleUserClick}>
                    <div className="user-name">{user.name}</div>
                    <div className="user-role">{user.roleName}</div>
                </div>
            </div>
        </div>
    )
}


export default User