import React, { useCallback, useEffect, useRef, useState } from "react"
import "./index.css"

const Options = React.memo(props => {

    const { options, onSelect, close } = props
    var [ selected, setSelected ] = useState()
    const optRef = useRef()

    useEffect(()=>{
        optRef.current.focus()
    }, [])

    const handleKeyDown = useCallback(e => {
        e.preventDefault()
        switch (e.key) {
            case 'ArrowDown':
                setSelected(((selected || 0) + 1) % options.length)
                break
            case 'ArrowUp':
                setSelected((options.length + (selected || 0) - 1) % options.length)
                break
            case 'Enter':
                let s = selected || 0
                let o = options[s]
                let name, value
                if(typeof(o)==="object"){
                    ({ name, value } = o)
                }else{
                    name = value = o
                }
                handleSelect(name, value, s)
                break
            default:
        }
    }, [selected])

    const handleSelect = (name, value, i) => {
        onSelect(name, value)
        setSelected(i)
    }

    return (
        <div className='options-main' tabIndex={0} onBlur={()=>{close()}} ref={optRef} onKeyDown={handleKeyDown}>
            {
                options.map((o, i) => {

                    let name, value
                    if(typeof(o)==="object"){
                        ({ name, value } = o)
                    }else{
                        name = value = o
                    }

                    return <div
                        className={`option-main ${selected==i && 'selected'}`}
                        onClick={()=> handleSelect(name, value, i)}
                    >{name}</div>
                })
            }
        </div>
    )
})

export default Options