import { StlyedLoader } from "./styled.jsx"
import "./index.css"

const Loader = props =>{
    return (
        <div className="loader-root">
            <div className="loader-main">
                <StlyedLoader delay={props.delay}/>
            </div>
            {props.progress && <div className="loader-text">{props.progress}</div>}
        </div>
    )
}

export default Loader
