import styled, { css } from "styled-components";

export const StyledIcon = styled.svg`
    ${
        props => css`
            width: ${props.size[0]||7}vw;
            height: ${props.size[0]||7}vw;

            @media (min-width:700px) and (min-aspect-ratio: 1){
                width: ${props.size[1]||3.5}vw;
                height: ${props.size[1]||3.5}vw;
            }

            @media (min-width:700px) and (min-aspect-ratio: 1.35){
                width: ${props.size[2]||2.333}vw;
                height: ${props.size[2]||2.333}vw;
            }
        `
    }

`