import "./index.css"

const Material = props => {

    return (
        <div>
        <iframe
            src="https://cdn.iskconmysore.org/content?path=folkapp/material/dummy.pdf#toolbar=0"
            title="Embedded PDF"
            style={{ width: '100%', height: '600px', border: 'none' }}
            onContextMenu={(e) => e.preventDefault()}
        >This browser does not support PDFs. Please download the PDF to view it.
        </iframe>
        </div>
    )

}

export default Material