import "./index.css"
import DP from '../../../components/custom/dp';
import Icon from '../../../components/custom/icon';
import Swipe from '../../../components/holders/swipe';
import moment from 'moment';
import { useCallback, useState } from "react";
import _ from "../../../_";
import Accordian from "../../../components/holders/accordian";
import MemberStats from "../../components/member-stats";

const Buddy = ({buddy, onSwipe})=>{

  const handleSwipe = ()=>{
    onSwipe(buddy)
  }
  var [ showStats, setShowStats ] = useState()
  const toggleStats = useCallback(()=>{
    setShowStats(!showStats)
  }, [showStats])

  return (
    <Swipe right={{color: _.statusColors[buddy.status], padWidth: 1, minDrag:10, maxDrag: 30, onSwipe: handleSwipe}}>
      <div className='buddy-box' key={buddy.name}>

        <div className='buddy-content'>
            <DP user={buddy} size={12} className={"buddy-dp"} enlarge/>

            <div className='buddy-details-cont' onClick={toggleStats}>
              <div className='buddy-details'>
                <div className='buddy-name'>{buddy.name}</div>
                <div className='buddy-preacher'>
                  <Icon name="supervisor-account" size={5} color="#555"/>
                  <div className='buddy-preacher-name'>{buddy.preacher}</div>
                </div>
              </div>

            <div className='buddy-right-info'>
              <div className='buddy-course'>{buddy.courseID}</div>
              <div className='buddy-notif'>
                {
                  buddy.dob && buddy.dob.dobDays()<=7?
                    <div className='buddy-notif-blob'>
                      <Icon className="buddy-notif-icon" name="cake" color="white" size={5.5}/>
                      <div>{moment(buddy.dob).format("DD MMM")}</div>
                    </div>
                  :null
                }
                {
                  buddy.snooze && buddy.snooze.snoozeDays()?
                      <div className='buddy-notif-blob'>
                      <Icon className="buddy-notif-icon" name="snooze" color="white" size={5.5}/>
                      <div>{moment(buddy.snooze).format("DD MMM")}</div>
                    </div>
                  :null
                }
              </div>
            </div>
          </div>
        </div>

        <Accordian show={showStats} maxHeight="30vw">
          <MemberStats member={buddy}/>
        </Accordian>

      </div>
    </Swipe>
  )
 
}


export default Buddy
