import Spinner from "../spinner"
import "./index.css"

const PageLoader = ({text, size, block})=>{
    return (
        <div className={block?"pageload-block":"pageload-main"}>
            <Spinner text={text} size={size}/>
        </div>
    )
}

export default PageLoader