import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import './prototypes.js'
import store from './store'
import API from './api/index.js'

store.auth()
  .then(auth => {
    const { redirectionURL, storeData } = auth || {}

    if (redirectionURL) {
      window.open(redirectionURL, "_self")
    } else {
      ReactDOM.render(
        <React.StrictMode>
          <App storeData={storeData} apiClient={new API(storeData)} />
        </React.StrictMode>,
        document.getElementById('root')
      )
    }
  })
  .catch(error => {
    alert(error)
  })
