import Glass from "../../custom/glass"
import Icon from "../../custom/icon"
import "./index.css"

const Drawer = ({ title, onClose, children }) => {

    const handleClose = ()=> {
        onClose && onClose()
    }

    return  (
        <div className="drw-root">
            <Glass dark opacity={0.25} onClick={handleClose}/>
            <div className="drw-main">
                <div className="drw-header">
                    <div className="drw-title">{title}</div>
                    <Icon name="close" size={5} onClick={handleClose}></Icon>
                </div>

                <div className="drw-body">{children}</div>
            </div>
        </div>
    )
}

export default Drawer