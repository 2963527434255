import React, { useEffect, useState } from 'react';
import "./index.css"
import Header from '../../components/custom/header';
import Buddy from './buddy';
import PageLoader from '../../components/info/pageloader';
import { useGlobal } from '../../context/global'
import ContactBubble from '../../components/holders/bubble/contact';

function Buddies(){

    const { auth, api } = useGlobal()

    var [ buddies, setBuddies ] = useState()
    var [ swipedBuddy, setSwipedBuddy ] = useState()
 
    useEffect(()=>{
      const id = auth.user.id
      api.call('/get-buddies', { id }).then(res=>{
        setBuddies(res.data)
      }).catch((err)=>{
        console.log(err)
      })
    }, [])

    const handleBuddySwipe = (buddy)=>{
      setSwipedBuddy(buddy)
    }

    const clearSwipedBuddy = ()=>{
      setSwipedBuddy(null)
    }

    return (
        <div>
          <Header title={"Buddies"}/>
          <div className='buddies-main'>
          {swipedBuddy?
            <ContactBubble phone={swipedBuddy.phone} name={swipedBuddy.name.split(" ")[0]} close={clearSwipedBuddy}/>:null}
          {
            buddies?
              <div className='buddies-holder'>

                {
                  buddies.map(buddy=>{
                    return <Buddy buddy={buddy} onSwipe={handleBuddySwipe}/>
                  })
                }
              </div>
            :<PageLoader text={"Loading buddy list..."}/>
          }
          </div>
        </div>
    )
}


export default Buddies
