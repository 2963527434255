import React, { useEffect, useRef, useState } from 'react';
import "./index.css"
import Header from '../../components/custom/header';
import Member from './member';
import PageLoader from '../../components/info/pageloader';
import { useGlobal } from "../../context/global"
import ListView from "../../components/holders/listView"

function Community() {

  const { auth, api } = useGlobal()

  var [community, setMembers] = useState()

  useEffect(() => {
    const id = auth.user.id
    api.call('/get-community', { id }).then(res => {
      setMembers(res.data)
    }).catch((err) => {
      console.log(err, err)
    })
  }, [])

  const listMap = member => <Member member={member}/>

  return (
    <div>
      <Header title={"Community"} />
      <div className='community-main'>
        {
          community ?
            <div className='community-holder'>
              <ListView list={community} map={listMap} height={94}/>
            </div>
            : <PageLoader text={"Loading member list..."} />
        }
      </div>
    </div>
  )
}


export default Community
