import { useState, useCallback, useEffect } from "react"
import Header from '../../components/custom/header';
import "./index.css"
import {CropLoader, useCropLoader} from "../../components/custom/croploader";
import { useGlobal } from '../../context/global'

import Icon from "../../components/custom/icon";
import PageLoader from "../../components/info/pageloader";
import { useNavigate } from "react-router-dom";

const EditUserPhoto = () => {

    const minPhotoSize = 512
    const cropHook = useCropLoader(minPhotoSize)

    const navigate = useNavigate()
    const { auth, api, deviceInfo } = useGlobal()

    const { user } = auth
    var [image, setImage] = useState(null)
    var [showBlockLoader, setShowBlockLoader] = useState(false)
    var [loaderText, setLoaderText] = useState("Opening image...")

    const initImageSelect = ()=>{
        cropHook.select()
    }

    useEffect(()=>{
        cropHook.selected && setImage(cropHook.selected)
    }, [cropHook.selected])

    useEffect(()=>{
        if(cropHook.cropped){
            setShowBlockLoader(true)
            cropHook.upload('/set-user-photo', user.id).then(()=>{
                setLoaderText("Updating...")
                const sw = navigator.serviceWorker
                if (sw) {
                    sw.controller.postMessage({ type: 'clearCache', key: user.id })
                }
                setTimeout(()=>{
                    navigate(-1)
                    setShowBlockLoader(false)
                }, 5000)
            }).catch(err=>{
                console.log(err)
            })
        }
    }, [cropHook.cropped])

    const onComplete = useCallback( cropValue => {

        setShowBlockLoader(true)
        setLoaderText("Cropping image...")

        const canvas = document.createElement('canvas')
        canvas.width = cropValue.width
        canvas.height = cropValue.height
        const ctx = canvas.getContext('2d')

        var img = new Image()
        img.onload = ()=>{
            ctx.drawImage(img, -cropValue.x, -cropValue.y)

            const scaledCanvas = document.createElement('canvas')
            scaledCanvas.width = minPhotoSize
            scaledCanvas.height = minPhotoSize
            const scaledCtx = scaledCanvas.getContext('2d')
            scaledCtx.drawImage(canvas, 0, 0, minPhotoSize, minPhotoSize)

            const imageDataURL = scaledCanvas.toDataURL('image/jpeg', 0.5)
            const filename = `${user.id}.jpeg`

            setLoaderText("Uploading image...")
            api.call('/set-user-photo', { imageDataURL, filename }).then(()=>{
                setLoaderText("Updating...")
                const sw = navigator.serviceWorker
                if (sw) {
                    sw.controller.postMessage({ type: 'clearCache', key: user.id })
                }
                setTimeout(()=>{
                    navigate(-1)
                    setShowBlockLoader(false)
                }, 5000)
            }).catch(err=>{
                console.log(err)
            })
            
            setImage(null)
        }
        img.src = image
    }, [image])

    const onAbort = ()=>{
        setImage(null)
    }

    return(
        <div className="edit-profile-root">
            {!image && <Header title={"Change User Photo"}/>}
            {showBlockLoader?<PageLoader block text={loaderText}/>:null}

            {image?
                <CropLoader src={image} minSize={minPhotoSize} onComplete={cropHook.crop} onAbort={onAbort}/>
                :
                <div className="editup-img-sel-cont">
                    
                        <div className="editup-img-sel" onClick={initImageSelect}>
                            <div style={{marginBottom: "1vw"}}><b>{"Tap here to"}</b></div>
                            <div className="editup-flex">
                                <Icon className="editup-img-sel-icon" name="image" color={"black"}/>
                                <div>{"Select image from gallery"}</div>
                            </div>
                            {deviceInfo.isMobileDevice?<>
                                <div className="editup-or">{"OR"}</div>
                                <div className="editup-flex">
                                    <Icon className="editup-img-sel-icon" name="photo-camera" color={"black"}/>
                                    <div>{"Open camera"}</div>
                                </div>
                            </>:null}
                        </div>
                </div>
            }

        </div>
    )
}

export default EditUserPhoto 